import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { Hide } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "hide"
    }}>{`Hide`}</h1>
    <p>{`A wrapper for hiding content on different breakpoints.`}</p>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<Hide all p={2}>\n  Always hide\n</Hide>\n<Hide xs p={2} bg=\"paletteLight.red01\">\n  Hide xs\n</Hide>\n<Hide xs sm p={2} bg=\"paletteLight.green01\">\n  Hide xs, sm\n</Hide>\n<Hide xs sm md p={2} bg=\"paletteLight.blue02\">\n  Hide xs, sm, md\n</Hide>\n<Hide xs sm md lg p={2} bg=\"paletteLight.purple02\">\n  Hide xs, sm, md, lg\n</Hide>\n<Hide xl p={2} bg=\"paletteLight.green03\">\n  Hide only on xl\n</Hide>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Hide,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Hide all p={2} mdxType="Hide">
    Always hide
  </Hide>
  <Hide xs p={2} bg="paletteLight.red01" mdxType="Hide">
    Hide xs
  </Hide>
  <Hide xs sm p={2} bg="paletteLight.green01" mdxType="Hide">
    Hide xs, sm
  </Hide>
  <Hide xs sm md p={2} bg="paletteLight.blue02" mdxType="Hide">
    Hide xs, sm, md
  </Hide>
  <Hide xs sm md lg p={2} bg="paletteLight.purple02" mdxType="Hide">
    Hide xs, sm, md, lg
  </Hide>
  <Hide xl p={2} bg="paletteLight.green03" mdxType="Hide">
    Hide only on xl
  </Hide>
    </Playground>
    <Props of={Hide} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      